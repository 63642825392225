import {
  FormField,
  Grid,
  Header,
  Icon,
  Input,
  Table,
  TextContent,
} from "@awsui/components-react";
import Container from "@awsui/components-react/container/internal";
import currency from "currency.js";
import React, { useState } from "react";

const tableArray = new Array(100);

const getItems = (
  cashAmount: string,
  stockPriceAtPurchase: string,
  startingPercentage: string,
  incrementPercentage: string
) => {
  let itemsArray: any = [];
  for (let i = 0; i < tableArray.length; i++) {
    let quantity: number = Number(cashAmount) / Number(stockPriceAtPurchase);
    let percentageForDisplay: number =
      Number(incrementPercentage) * i + Number(startingPercentage);
    let percentage: number =
      (Number(incrementPercentage) * i + Number(startingPercentage)) / 100 + 1;
    let calculatedStockPrice = Number(stockPriceAtPurchase) * percentage;
    itemsArray.push({
      stockPrice: currency(calculatedStockPrice, {
        precision: 3,
      }).format(),
      percentage: percentageForDisplay.toFixed(2),
      profitLoss: currency(
        quantity * calculatedStockPrice -
          quantity * Number(stockPriceAtPurchase)
      ).format(),
    });
  }
  return itemsArray;
};

export const ProfitCalculator = () => {
  const [cashAmount, setCashAmount] = useState("");
  const [stockPriceAtPurchase, setStockPriceAtPurchase] = useState("");
  const [startingPercentage, setStartingPercentage] = useState("-0.5");
  const [incrementPercentage, setIncrementPercentage] = useState("0.5");
  const columnDefinitions = [
    {
      id: "percentage",
      header: "Percentage",
      cell: (item: any) => {
        if (item.percentage == 0) {
          return (
            <div>
              <Icon variant="disabled" name="treeview-collapse" />
              {item.percentage}%
            </div>
          );
        }
        if (item.percentage > 0) {
          return (
            <div>
              <Icon variant="success" name="caret-up-filled" />
              {item.percentage}%
            </div>
          );
        } else {
          return (
            <div>
              <Icon variant="error" name="caret-down-filled" />
              {item.percentage}%
            </div>
          );
        }
      },
    },
    {
      id: "stockprice",
      header: "Stock Price",
      cell: (item: any) => {
        return item.stockPrice;
      },
    },
    {
      id: "profitloss",
      header: "Profit / Loss",
      cell: (item: any) => {
        return item.profitLoss;
      },
    },
  ];

  const items = getItems(
    cashAmount,
    stockPriceAtPurchase,
    startingPercentage,
    incrementPercentage
  );

  return (
    <div>
      <Container
        header={
          <Header>
            Profit Calculator{" "}
            <TextContent>
              <small>Inspired by Stonks View</small>
            </TextContent>
          </Header>
        }
      >
        <Grid>
          <FormField
            description="The amount of cash you spent on the stock"
            label="Cash Amount"
          >
            <Input
              value={cashAmount}
              onChange={(event) => setCashAmount(event.detail.value)}
            />
          </FormField>
          <FormField
            description="The price of the stocks when you made the purchase"
            label="Stock Price at Purchase"
          >
            <Input
              value={stockPriceAtPurchase}
              onChange={(event) => setStockPriceAtPurchase(event.detail.value)}
            />
          </FormField>
          <FormField
            description="The table will display values beginning with this percentage"
            label="Starting Percentage"
          >
            <Input
              value={startingPercentage}
              onChange={(event) => setStartingPercentage(event.detail.value)}
            />
          </FormField>
          <FormField
            description="The amount of percentage that you would like to increment at"
            label="Increment by %"
          >
            <Input
              value={incrementPercentage}
              onChange={(event) => setIncrementPercentage(event.detail.value)}
            />
          </FormField>
        </Grid>
      </Container>
      <Table columnDefinitions={columnDefinitions} items={items} />
    </div>
  );
};
