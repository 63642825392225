import React from "react"
import { SideNavigation, TextContent } from "@awsui/components-react"


export const Navigation = () => {
 return (
     <SideNavigation
        header={{
            href: "",
            text: (
                <div>
                    <TextContent>
                        <small>Stonks Assistance Platform</small>
                    </TextContent>
                    YUNA
                </div>
            ) as any
        }}
     />
 )
}