import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AppLayout } from '@awsui/components-react';
import '@awsui/global-styles/index.css'
import Navigation from './components/navigation/';
import ProfitCalculator from './pages/profit-calculator';

function App() {
  return (
    <AppLayout
      toolsHide={true}
      navigation={<Navigation/>}
      content={
        <ProfitCalculator/>
      }
    />
  );
}

export default App;
